import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const MemberCard = ({ title, description, image }) => {
  const img = getImage(image);

  return (
    <article>
      {img && (
        <div className="aspect-square relative mb-4">
          <GatsbyImage
            image={img}
            // title={image.title}
            alt={image.description}
          />
          <span className="w-full bg-gradient-109 from-blue-navy to-blue-bold h-[6px] absolute left-0 bottom-0" />
        </div>
      )}
      {(title || description) && (
        <div className="">
          {title && (
            <p>
              <strong>{title}</strong>
            </p>
          )}
          {description && <p className="u-body-sm">{description}</p>}
        </div>
      )}
    </article>
  );
};

MemberCard.defaultProps = {
  title: '',
  description: '',
};

MemberCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object.isRequired,
};

export default MemberCard;
