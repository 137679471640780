import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Atoms
import MemberCard from '../../atoms/card-member';

// Component
import Carousel from '../carousel';

const TeamMemberBlock = React.forwardRef(
  ({ title, description, buttons, members, ...props }, ref) => {
    return (
      <Carousel
        className={cn('bg-grey-light py-9 lg:py-20', props.className)}
        title={title}
        description={description}
        buttons={buttons}
        slides={members.map((member, key) => (
          <MemberCard
            key={`team-member-card-${key}`}
            title={member.title}
            description={member.jobTitle}
            image={member.image}
          />
        ))}
        numberOfSlides={{
          mobile: 1.4,
          tablet: 2.4,
          desktop: 5,
        }}
        ref={ref}
      />
    );
  }
);

TeamMemberBlock.defaultProps = {
  title: '',
  description: '',
  buttons: [],
};

TeamMemberBlock.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      label: PropTypes.string,
      ariaLabel: PropTypes.string,
      variant: PropTypes.oneOf(['Primary', 'Secondary', 'Tertiary']),
      opensInNewTab: PropTypes.bool,
      externalUrl: PropTypes.string,
      internalUrl: PropTypes.arrayOf(PropTypes.object),
    })
  ),
  members: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      jobTitle: PropTypes.string,
      image: PropTypes.object,
    })
  ).isRequired,
};

export default TeamMemberBlock;
