import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Components
import { BuilderWrapper } from './builder';

const BuilderApp = ({ columns }) => {
  const { length } = columns;
  const [blockRefs, setBlockRefs] = useState([]);

  useEffect(() => {
    setBlockRefs((r) =>
      Array(length)
        .fill()
        .map((_, i) => r[i] || createRef())
    );
  }, [length]);

  useEffect(() => {
    blockRefs.map((ref, j) => {
      if (
        ref?.current !== null &&
        ref?.current.dataset.name === 'ContentfulPartnerBlock' &&
        ref?.current?.offsetHeight
      ) {
        // j - 1 === Above Partner
        if (blockRefs[j - 1].current !== null) {
          blockRefs[j - 1].current.style.paddingBottom = `${
            64 + ref.current.offsetHeight / 2
          }px`;
        }
        // j + 1 === Below Partner
        if (blockRefs[j + 1].current !== null) {
          blockRefs[j + 1].current.style.paddingTop = `${
            64 + ref.current.offsetHeight / 2
          }px`;
        }
      }
    });
  }, [blockRefs]);

  return (
    <div className="py-16 lg:py-32 space-y-16 lg:space-y-32">
      {columns.map((fc, key) => (
        <BuilderWrapper
          key={`fc-${key}`}
          index={key}
          isFirst={key === 0}
          isLast={key === columns.length - 1}
          type={fc?.__typename || ''}
          previousType={key > 0 && columns[key - 1].__typename}
          nextType={key < columns.length - 1 && columns[key + 1].__typename}
          params={fc}
          ref={blockRefs[key]}
        />
      ))}
    </div>
  );
};

BuilderApp.propTypes = {
  columns: PropTypes.array.isRequired,
};

export default BuilderApp;
