import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { breakpoints, useWindowDimensions } from '../components/helpers';

const TestimonialCard = ({
  title,
  description,
  companyName,
  authorImage,
  authorName,
  authorJobTitle,
  isAnyOpen,
  setIsAnyOpen,
}) => {
  const descriptionRef = useRef(null);
  const authorImg = getImage(authorImage);
  const [isOpen, setIsOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  const expand = () => {
    return (isDesktop && isAnyOpen) || (!isDesktop && isOpen);
  };

  useEffect(() => {
    if (window?.innerWidth >= breakpoints.lg) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }, [useWindowDimensions().width]);

  return (
    <article className="bg-white shadow-default rounded p-6 lg:p-10 pb-36 flex lg:pb-40 relative w-full first:mb-8 first:lg:mb-0 first:lg:mr-11">
      {(title || description) && (
        <div>
          {title && <p className="u-h5 mb-3 font-bold">{title}</p>}
          {description && (
            <div
              className="relative overflow-hidden transition-all"
              style={{
                maxHeight: `${
                  expand() ? descriptionRef?.current?.offsetHeight : 72
                }px`,
              }}
            >
              <p className="text-body-sm" ref={descriptionRef}>
                {description}
              </p>
              {!expand() && (
                <div className="absolute bottom-0 right-0 u-body-sm bg-white">
                  <span>... </span>
                  <button
                    type="button"
                    className="text-blue-bold underline hover:no-underline font-bold"
                    onClick={() => {
                      setIsOpen(true);
                      setIsAnyOpen(true);
                    }}
                    aria-label={`Read more testimonial from ${authorName}`}
                  >
                    Read more
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <div className="flex absolute bottom-6 lg:bottom-10 left-6 lg:left-10">
        {authorImg && (
          <div className="flex-initial max-w-[70px] max-h-[70px] mr-4 rounded-full overflow-hidden">
            <GatsbyImage
              image={authorImg}
              // title={authorImage.title}
              alt={authorImage.description}
            />
          </div>
        )}
        <div className="flex-auto text-grey-testimonial">
          <p className="text-body-sm">{authorName}</p>
          {authorJobTitle && <p className="text-body-sm">{authorJobTitle}</p>}
          {companyName && <p className="text-body-sm">{companyName}</p>}
        </div>
      </div>
    </article>
  );
};

TestimonialCard.defaultProps = {
  companyName: '',
  authorImage: {},
  authorJobTitle: '',
  isAnyOpen: false,
  setIsAnyOpen: () => {},
};

TestimonialCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  companyName: PropTypes.string,
  authorImage: PropTypes.object,
  authorName: PropTypes.string.isRequired,
  authorJobTitle: PropTypes.string,
  isAnyOpen: PropTypes.bool,
  setIsAnyOpen: PropTypes.func,
};

export default TestimonialCard;
