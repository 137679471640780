import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Atoms
import IconCard from '../../atoms/card-icon';
import Button from '../../atoms/button';

const BenefitBlock = ({ title, description, benefits, ...props }) => {
  const [state, setState] = useState({
    showAll: false,
    noToShow: 3,
  });

  return (
    <div
      className={cn(
        'xl:container mobile:px-4 tablet:px-8 lg:px-16',
        props.className
      )}
    >
      <div className="grid grid-cols-12 gap-x-4 lg:gap-x-8">
        {(title || description) && (
          <div className="col-span-12 lg:col-start-4 lg:col-span-6">
            <div className="space-y-3 lg:text-center">
              {title && <h2 className="u-h3">{title}</h2>}
              {description && <p>{description}</p>}
            </div>
          </div>
        )}
        {benefits.length > 0 && (
          <div className="col-span-12 lg2:col-start-2 lg2:col-span-10 mt-8 lg:mt-12">
            <div className="lg:flex lg:justify-start lg:items-start lg:flex-wrap -mb-4 lg:-mb-16">
              {benefits.map((benefit, key) => (
                <IconCard
                  key={`icon-card-${key}`}
                  title={benefit.title}
                  icon={benefit.icon}
                  description={benefit.description}
                  className={`lg:flex-none lg:w-1/3 lg:px-6 lg2:px-8 mb-4 lg:mb-16 ${
                    key >= state.noToShow && !state.showAll
                      ? 'mobile+tablet:hidden'
                      : ''
                  }`}
                />
              ))}
            </div>
            {benefits.length > state.noToShow && !state.showAll && (
              <div className="mt-8 lg:hidden">
                <Button
                  onClick={() => {
                    setState({ showAll: true });
                  }}
                  variant="tertiary"
                  label="Show all"
                  aria-label="Show all benefits"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

BenefitBlock.defaultProps = {
  title: '',
  description: '',
  benefits: [],
};

BenefitBlock.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      icon: PropTypes.string,
    })
  ),
};

export default BenefitBlock;
