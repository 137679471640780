import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Atoms
import Video from '../../atoms/video';
import Button from '../../atoms/button';

// Components
import { generateUrlFromContentModel } from '../helpers';

const VideoBlock = ({ title, description, video, ctas, ...props }) => {
  return (
    <div
      className={cn(
        'xl:container mobile:px-4 tablet:px-8 lg:px-16',
        props.className
      )}
    >
      <div className="grid grid-cols-12 gap-x-4 lg:gap-x-8">
        <div className="col-span-12 lg:col-start-3 lg:col-span-8 space-y-9">
          {(title || description) && (
            <div className="space-y-4 lg:space-y-6">
              {title && <h2 className="u-h3">{title}</h2>}
              {description && <p>{description}</p>}
            </div>
          )}
          {video?.id && (
            <Video
              title={title}
              id={video?.id}
              platform={video?.platform}
              thumbnail={video?.thumbnail}
            />
          )}
          {ctas && ctas.length > 0 && (
            <div className="flex flex-wrap items-center justify-center">
              {ctas.map((cta, key) => (
                <Button
                  key={`video-cta-button-${key}`}
                  as={
                    (cta?.internalUrl && cta?.internalUrl.length) ||
                    cta?.externalUrl
                      ? 'a'
                      : 'button'
                  }
                  variant={cta?.variant.toLowerCase()}
                  href={
                    cta?.internalUrl && cta?.internalUrl.length
                      ? generateUrlFromContentModel(cta?.internalUrl[0])
                      : cta?.externalUrl
                  }
                  label={cta?.label || cta.title}
                  aria-label={cta?.ariaLabel}
                  newTab={cta?.opensInNewTab}
                  className="mb-3 mr-3 last:mr-0"
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

VideoBlock.defaultProps = {
  title: '',
  description: '',
  video: {
    platform: '',
    id: '',
    thumbnail: {},
  },
  ctas: [],
};

VideoBlock.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  video: PropTypes.shape({
    platform: PropTypes.string,
    id: PropTypes.string,
    thumbnail: PropTypes.object,
  }),
  ctas: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      label: PropTypes.string,
      ariaLabel: PropTypes.string,
      variant: PropTypes.oneOf(['Primary', 'Secondary', 'Tertiary']),
      opensInNewTab: PropTypes.bool,
      externalUrl: PropTypes.string,
      internalUrl: PropTypes.arrayOf(PropTypes.object),
    })
  ),
};

export default VideoBlock;
