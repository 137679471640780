import cn from 'classnames';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';

import { renderIconBasedOnId } from '../components/helpers';

const IconCard = ({
  title,
  description,
  icon,
  variant,
  isCentered,
  iconSize,
  margin,
  underline,
  size,
  ...props
}) => {
  const isSSR = typeof window === 'undefined';

  return (
    <div className={cn(isCentered && 'text-center', props.className)}>
      {!isSSR && (
        <Suspense fallback={<p>Loading...</p>}>
          {renderIconBasedOnId(
            icon,
            cn(
              variant === 'default' && 'fill-blue-bold',
              // variant === 'blue' && 'fill-blue-navy',
              variant === 'white' && 'fill-white',
              isCentered && 'mx-auto',
              iconSize || 'w-12 h-12',
              margin || 'mb-3 lg:mb-6'
            )
          )}
        </Suspense>
      )}
      {(title || description) && (
        <div className="space-y-1 lg:space-y-3">
          {title && (
            <p
              className={cn(
                variant === 'default' && 'text-grey-font-1 font-bold',
                // variant === 'blue' && 'text-white',
                variant === 'white' && 'text-white',
                underline && 'underline',
                size === 'small'
                  ? 'u-body-sm'
                  : size === 'medium'
                  ? 'u-body-md'
                  : 'u-body-lg'
              )}
            >
              {title}
            </p>
          )}
          {description && (
            <p
              className={cn(
                variant === 'default' && 'text-grey-font-2',
                // variant === 'blue' && 'text-white',
                variant === 'white' && 'text-white',
                size === 'small'
                  ? 'hidden'
                  : size === 'medium'
                  ? 'u-body-sm'
                  : ''
              )}
            >
              {description}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

IconCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.string,
  variant: PropTypes.string,
  iconSize: PropTypes.string,
  underline: PropTypes.bool,
  margin: PropTypes.string,
  size: PropTypes.oneOf(['', 'small', 'medium', 'large']),
  isCentered: PropTypes.bool,
};

IconCard.defaultProps = {
  description: '',
  variant: 'default',
  size: 'large',
  iconSize: null,
  margin: null,
  underline: false,
  isCentered: false,
  icon: '',
};

export default IconCard;
