import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Icons
import PlayIcon from '../icons/icon-play.svg';

// Logos
import EmblemLogo from '../logos/logo-emblem.svg';

const PlayButton = React.forwardRef(({ disabled, ...props }, ref) => {
  return (
    <button
      ref={ref}
      type="button"
      className={cn('w-32 h-32 group', props.className)}
      disabled={disabled}
    >
      <img
        src={EmblemLogo}
        alt=""
        className={cn(
          'absolute top-0 left-0 w-full h-full touch-none pointer-events-none -z-10 opacity-0 animate-none transition-opacity duration-0',
          'group-hover:opacity-50 group-hover:animate-spin-slow group-hover:duration-1000'
        )}
      />
      <span
        className={cn(
          'rounded-full w-16 h-16 bg-blue-bold inline-flex items-center justify-center transition-all duration-0',
          'group-hover:bg-blue-bold-hover group-hover:shadow-hover group-hover:duration-1000',
          'group-active:bg-blue-bold-active',
          'disabled:bg-grey-disabled'
        )}
      >
        <PlayIcon className="w-[21px] h-6 fill-white transform translate-x-[2px]" />
      </span>
    </button>
  );
});

PlayButton.defaultProps = {
  disabled: true,
};

PlayButton.propTypes = {
  disabled: PropTypes.bool,
};

export default PlayButton;
