import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const propTypes = {
  logo: PropTypes.object.isRequired, // GatsbyImage
};

const defaultProps = {};

const CardPartner = ({ logo, ...props }) => {
  const img = getImage(logo);

  return (
    <div className={props.className}>
      <GatsbyImage
        image={img}
        // title={logo.title}
        alt={logo.description}
      />
    </div>
  );
};

CardPartner.propTypes = propTypes;
CardPartner.defaultProps = defaultProps;

export default CardPartner;
