import React from 'react';
import PropTypes from 'prop-types';

// Atoms
import IconCard from '../../atoms/card-icon';

// Forms
import BookADemoForm from '../../forms/book-a-demo-form';

// Logos
import BgLogo from '../../logos/logo-bg.svg';

const FormBlock = React.forwardRef(({ title, description, benefits }, ref) => {
  return (
    <div
      ref={ref}
      className="bg-gradient-180 from-blue-dark to-blue-bold text-white overflow-hidden py-10 lg:py-20 relative"
    >
      <img
        src={BgLogo}
        alt=""
        className="w-[200vw] md:w-[150vw] max-w-[1000px] lg:max-w-[3000px] h-auto absolute top-1/2 left-1/4 transform -translate-y-1/2 -rotate-[140deg] opacity-50 pointer-events-none touch-none mobile+tablet:hidden"
      />
      <div className="relative">
        <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16">
          {(title || description) && (
            <div className="grid grid-cols-12 gap-x-4 lg:gap-x-8">
              <div className="col-span-12 lg:col-start-3 lg:col-span-8">
                <div className="text-center space-y-4 lg:space-y-6">
                  <h2 className="u-h3">{title}</h2>
                  {description && <p>{description}</p>}
                </div>
              </div>
            </div>
          )}
          <div className="grid grid-cols-12 gap-x-4 lg:gap-x-8 mt-8 lg:mt-20">
            <div className="col-span-12 lg:col-start-2 lg:col-span-4 order-2 lg:order-1 mobile+tablet:mt-10">
              {benefits?.length > 0 && (
                <div className="grid grid-cols-2 gap-4 lg:gap-8">
                  {benefits.map((benefit, key) => (
                    <div className="col-span-1" key={`icon-card-${key}`}>
                      <IconCard
                        title={benefit.title}
                        icon={benefit.icon}
                        description={benefit.description}
                        variant="white"
                        isCentered
                        size="small"
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="col-span-12 lg:col-start-7 lg:col-span-5 order-1 lg:order-2">
              <div className="bg-white rounded p-4 lg:p-8 text-grey-font-1">
                <BookADemoForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

FormBlock.defaultProps = {
  description: '',
};

FormBlock.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      icon: PropTypes.string,
    })
  ).isRequired,
};

export default FormBlock;
