import React, { useState, useCallback, useEffect, useRef } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import Glider from 'react-glider';
import styled from 'styled-components';

// Atoms
import Button from '../atoms/button';

// Components
import {
  breakpoints,
  useWindowDimensions,
  generateUrlFromContentModel,
} from './helpers';

// Icons
import LeftIcon from '../icons/icon-left.svg';
import RightIcon from '../icons/icon-right.svg';

const CarouselWrapper = styled.div`
  .glider {
    &::-webkit-scrollbar {
      height: 0;
      opacity: 0;
    }
  }

  .glider-track {
    padding: 30px 0;
    margin: 0 8px;

    @media (min-width: 768px) {
      margin: 0 24px;
    }

    @media (min-width: 1024px) {
      margin: 0 48px;
    }

    @media (min-width: 1440px) {
      margin: 0 calc(((100vw - 1440px) / 2) - 16px + 120px);
    }
  }

  button.disabled {
    pointer-events: none;
    touch-action: none;
    opacity: 0.2;
  }
`;

const Carousel = React.forwardRef(
  (
    { title, description, buttons, slides, numberOfSlides, isCenter, ...props },
    ref
  ) => {
    const [leftEl, setLeftEl] = useState(null);
    const [rightEl, setRightEl] = useState(null);
    const [isDesktop, setIsDesktop] = useState(false);

    const leftRefCb = useCallback((node) => {
      if (node !== null) {
        setLeftEl(node);
      }
    }, []);

    const rightRefCb = useCallback((node) => {
      if (node !== null) {
        setRightEl(node);
      }
    }, []);

    const gliderRef = useRef(null);

    useEffect(() => {
      if (gliderRef?.current) {
        gliderRef.current.updateControls();
      }
    }, [leftEl, rightEl]);

    useEffect(() => {
      if (window?.innerWidth >= breakpoints.lg) {
        setIsDesktop(true);
      } else {
        setIsDesktop(false);
      }
    }, [useWindowDimensions().width]);

    return (
      <CarouselWrapper className={props.className} ref={ref}>
        {(title || description) && (
          <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16 lg:mb-4">
            <div className="grid grid-cols-12 gap-x-4 lg:gap-x-8">
              <div
                className={cn(
                  'col-span-12',
                  isCenter ? 'lg:col-start-4 lg:col-span-6 text-center' : ''
                )}
              >
                <div
                  className={cn(
                    'flex items-end',
                    isCenter ? 'justify-center' : 'justify-between'
                  )}
                >
                  <div
                    className={cn(
                      `flex-initial space-y-2.5 lg:space-y-4`,
                      isCenter ? '' : 'lg:max-w-[66.667%]'
                    )}
                  >
                    {title && <h2 className="u-h4">{title}</h2>}
                    {description && <p>{description}</p>}
                  </div>

                  {!isCenter && isDesktop && (
                    <div className="space-x-6">
                      <Button
                        ref={leftRefCb}
                        variant="navigation"
                        aria-label="Trigger previous call to action"
                        icon={<LeftIcon className="fill-current w-8 h-8" />}
                      />
                      <Button
                        ref={rightRefCb}
                        variant="navigation"
                        aria-label="Trigger next call to action"
                        icon={<RightIcon className="fill-current w-8 h-8" />}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {slides && slides.length > 0 && (
          <div className={cn(isCenter ? 'relative' : '')}>
            {isCenter && isDesktop && (
              <>
                <Button
                  ref={leftRefCb}
                  variant="navigation"
                  aria-label="Trigger previous call to action"
                  icon={<LeftIcon className="fill-current w-8 h-8" />}
                  className="absolute top-1/2 transform -translate-y-1/2 xl:-translate-x-full z-10 left-0 lg:ml-2 xl:ml-carousel-offset"
                />
                <Button
                  ref={rightRefCb}
                  variant="navigation"
                  aria-label="Trigger next call to action"
                  icon={<RightIcon className="fill-current w-8 h-8" />}
                  className="absolute top-1/2 transform -translate-y-1/2 xl:translate-x-full z-10 right-0 lg:mr-2 xl:mr-carousel-offset"
                />
              </>
            )}
            <Glider
              className="relative"
              ref={gliderRef}
              hasArrows
              draggable
              slidesToShow={numberOfSlides.mobile}
              slidesToScroll={1}
              responsive={[
                {
                  breakpoint: breakpoints.md,
                  settings: {
                    slidesToShow: numberOfSlides.tablet,
                  },
                },
                {
                  breakpoint: breakpoints.lg,
                  settings: {
                    slidesToShow: 'auto',
                    itemWidth:
                      (useWindowDimensions().width - 2 * 64 + 48) /
                      numberOfSlides.desktop,
                    exactWidth: true,
                  },
                },
                {
                  breakpoint: breakpoints.xl,
                  settings: {
                    slidesToShow: 'auto',
                    itemWidth:
                      (useWindowDimensions().width -
                        2 * ((useWindowDimensions().width - 1440) / 2 + 120) +
                        48) /
                      numberOfSlides.desktop,
                    exactWidth: true,
                  },
                },
              ]}
              arrows={{
                prev: leftEl,
                next: rightEl,
              }}
            >
              {slides.map((slide, key) => (
                <div
                  className="glider-slide px-2 lg:px-6"
                  key={`carousel-${key}`}
                >
                  {slide}
                </div>
              ))}
            </Glider>
          </div>
        )}
        {((buttons && buttons.length > 0) || !isDesktop) && (
          <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16 mt-2 lg:mt-4">
            <div
              className={cn(
                'flex flex-wrap items-center justify-between -mb-4',
                isCenter ? 'lg:justify-center' : ''
              )}
            >
              {buttons && buttons.length > 0 && (
                <div className="flex items-center justify-start mb-4">
                  {buttons.map((button, key) => (
                    <Button
                      key={`carousel-button-${key}`}
                      as={
                        (button?.internalUrl && button?.internalUrl.length) ||
                        button?.externalUrl
                          ? 'a'
                          : 'button'
                      }
                      variant={button?.variant.toLowerCase()}
                      href={
                        button?.internalUrl && button?.internalUrl.length
                          ? generateUrlFromContentModel(button?.internalUrl[0])
                          : button?.externalUrl
                      }
                      label={button?.label || button.title}
                      aria-label={button?.ariaLabel}
                      newTab={button?.opensInNewTab}
                      className="mr-4 lg:mr-6 last:mr-0"
                    />
                  ))}
                </div>
              )}
              {!isDesktop && (
                <div className="space-x-6 mb-4">
                  <Button
                    ref={leftRefCb}
                    variant="navigation"
                    aria-label="Trigger previous call to action"
                    icon={<LeftIcon className="fill-current w-8 h-8" />}
                  />
                  <Button
                    ref={rightRefCb}
                    variant="navigation"
                    aria-label="Trigger next call to action"
                    icon={<RightIcon className="fill-current w-8 h-8" />}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </CarouselWrapper>
    );
  }
);

Carousel.defaultProps = {
  title: '',
  description: '',
  buttons: [],
  numberOfSlides: {
    mobile: 1.2,
    tablet: 2.2,
    desktop: 4,
  },
  isCenter: false,
};

Carousel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      label: PropTypes.string,
      ariaLabel: PropTypes.string,
      variant: PropTypes.oneOf(['Primary', 'Secondary', 'Tertiary']),
      opensInNewTab: PropTypes.bool,
      externalUrl: PropTypes.string,
      internalUrl: PropTypes.arrayOf(PropTypes.object),
    })
  ),
  slides: PropTypes.arrayOf(PropTypes.node).isRequired,
  numberOfSlides: PropTypes.shape({
    mobile: PropTypes.number,
    tablet: PropTypes.number,
    desktop: PropTypes.number,
  }),
  isCenter: PropTypes.bool,
};

export default Carousel;
