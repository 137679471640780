import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cn from 'classnames';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

// Atoms
import Button from '../../atoms/button';

// Components
import { richTextOptions, generateUrlFromContentModel } from '../helpers';

// Styled components
const ContentDiv = styled.div.attrs({
  className: 'space-y-4 lg:space-y-6',
})`
  > h2 + p,
  > h3 + p {
    margin-top: 12px !important;
  }

  > ul,
  > ol {
    margin-top: 6px !important;
  }

  > div[data-gatsby-image-wrapper] {
    margin-top: 28px !important;

    @media (min-width: 1024px) {
      margin-top: 36px !important;
    }
  }
`;

// Special center button div if no ContentfulAsset found in body
// const CenterButtonDiv = styled(ContentDiv).attrs({
//   className: 'flex flex-col',
// })`
//   & > a[href],
//   & > button {
//     align-self: center;
//   }
// `;

const ContentBlock = ({ body, buttons, ...props }) => {
  const classes = cn(
    'flex flex-wrap -mb-3 mt-5 lg:mt-8',
    body?.references.find((ref) => ref?.__typename === 'ContentfulAsset')
      ? 'justify-center'
      : ''
  );

  return (
    <div
      className={cn(
        'xl:container mobile:px-4 tablet:px-8 lg:px-16',
        props.className
      )}
    >
      <div className="grid grid-cols-12 gap-x-4 lg:gap-x-8">
        <div className="col-span-12 lg:col-start-3 lg:col-span-8">
          <ContentDiv>{renderRichText(body, richTextOptions)}</ContentDiv>
          {buttons && buttons.length > 0 && (
            <div className={classes}>
              {buttons.map((button, key) => (
                <Button
                  key={`content-button-${key}`}
                  as={
                    (button?.internalUrl && button?.internalUrl.length) ||
                    button?.externalUrl
                      ? 'a'
                      : 'button'
                  }
                  variant={button?.variant.toLowerCase()}
                  href={
                    button?.internalUrl && button?.internalUrl.length
                      ? generateUrlFromContentModel(button?.internalUrl[0])
                      : button?.externalUrl
                  }
                  label={button?.label || button.title}
                  aria-label={button?.ariaLabel}
                  newTab={button?.opensInNewTab}
                  className="mb-3 mr-3 last:mr-0"
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ContentBlock.defaultProps = {
  buttons: [],
};

ContentBlock.propTypes = {
  body: PropTypes.object.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      label: PropTypes.string,
      ariaLabel: PropTypes.string,
      variant: PropTypes.oneOf(['Primary', 'Secondary', 'Tertiary']),
      opensInNewTab: PropTypes.bool,
      externalUrl: PropTypes.string,
      internalUrl: PropTypes.arrayOf(PropTypes.object),
    })
  ),
};

export default ContentBlock;
