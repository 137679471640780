import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Atoms
import Button from '../../atoms/button';

// Logos
import BgLogo from '../../logos/logo-bg.svg';

// Components
import {
  generateUrlFromContentModel,
  useScroll,
  useWindowDimensions,
} from '../helpers';

// Styled components

const CtaBlock = React.forwardRef(
  ({ title, description, buttons, ...props }, ref) => {
    const { scrollTop } = useScroll(50);
    const { height } = useWindowDimensions();

    return (
      <div
        className={cn(
          'bg-blue-navy py-16 lg:py-40 relative text-white text-center overflow-hidden',
          props.className
        )}
        ref={ref}
      >
        <img
          src={BgLogo}
          alt=""
          style={{
            transform: `translate(-50%, -50%) rotate(${
              ref?.current &&
              scrollTop + height - ref.current.offsetTop > 0 &&
              scrollTop <=
                ref.current.offsetTop +
                  ref.current.getBoundingClientRect().height
                ? ((scrollTop + height - ref.current.offsetTop) /
                    ref.current.getBoundingClientRect().height) *
                  120
                : 0
            }deg)`,
          }}
          className="w-[200vw] md:w-[150vw] max-w-[1000px] lg:max-w-[3000px] h-auto absolute top-0 left-1/2 opacity-50 pointer-events-none touch-none"
        />
        <div className="relative">
          <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16">
            <div className="grid grid-cols-12 gap-x-4 lg:gap-x-8">
              <div className="col-span-12 lg:col-start-3 lg:col-span-8">
                {(title || description) && (
                  <div className="space-y-4 lg:space-y-6">
                    {title && <h2 className="u-h3">{title}</h2>}
                    {description && <p className="u-body-lg">{description}</p>}
                  </div>
                )}
                {buttons && buttons.length > 0 && (
                  <div className="mt-10 -mb-4 lg:-mb-6 flex flex-wrap items-center justify-center">
                    {buttons.map((button, key) => (
                      <Button
                        key={`cta-button-${key}`}
                        as={
                          (button?.internalUrl && button?.internalUrl.length) ||
                          button?.externalUrl
                            ? 'a'
                            : 'button'
                        }
                        variant={button?.variant.toLowerCase()}
                        href={
                          button?.internalUrl && button?.internalUrl.length
                            ? generateUrlFromContentModel(
                                button?.internalUrl[0]
                              )
                            : button?.externalUrl
                        }
                        label={button?.label || button.title}
                        aria-label={button?.ariaLabel}
                        newTab={button?.opensInNewTab}
                        className="w-full sm:w-auto mb-4 lg:mb-6 sm:mr-4 lg:mr-6 last:mr-0"
                      />
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

CtaBlock.defaultProps = {
  title: '',
  description: '',
  buttons: [],
};

CtaBlock.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      label: PropTypes.string,
      ariaLabel: PropTypes.string,
      variant: PropTypes.oneOf(['Primary', 'Secondary', 'Tertiary']),
      opensInNewTab: PropTypes.bool,
      externalUrl: PropTypes.string,
      internalUrl: PropTypes.arrayOf(PropTypes.object),
    })
  ),
};

export default CtaBlock;
