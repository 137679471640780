/* global document window */

import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

// Atoms
import { TextField, MenuItem } from '@mui/material';
import Button from '../atoms/button';

const BookADemoForm = () => {
  const [state, setState] = useState({
    submitting: false,
  });

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('This is a required field'),
    last_name: Yup.string().required('This is a required field'),
    // full_name: Yup.string().required('This is a required field'),
    emailAddress: Yup.string()
      .required('This is a required field')
      .email('Email must be formatted correctly'),
    phone: Yup.string(),
    // company: Yup.string().required('This is a required field'),
    dealershipType: Yup.string(),
    // dealershipSize: Yup.string(),
    // preferredContactMethod: Yup.string().required('This is a required field'),
    message: Yup.string(),
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (data) => {
    setState({ submitting: true });

    const form = document.createElement('form');
    form.method = 'POST';
    form.action =
      'https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8';

    // Your org ID
    const elementOID = document.createElement('input');
    elementOID.name = 'oid';
    elementOID.value = '00D20000000I9ep';
    elementOID.setAttribute('type', 'hidden');
    form.appendChild(elementOID);

    // SFDC redirects to RetURL in the response to the form post
    const elementRetURL = document.createElement('input');
    elementRetURL.name = 'retURL';
    elementRetURL.value = `${window.location.hostname}/thank-you`;
    elementRetURL.setAttribute('type', 'hidden');
    form.appendChild(elementRetURL);

    // These fields are optional debugging elements. Uncomment
    // these lines if you wish to test in debug mode.
    // const elementDebug = document.createElement('input');
    // elementDebug.name = 'debug';
    // elementDebug.value = '1';
    // elementDebug.setAttribute('type', 'hidden');
    // form.appendChild(elementDebug);

    // const elementDebugEmail = document.createElement('input');
    // elementDebugEmail.name = 'debugEmail';
    // elementDebugEmail.value = 'xavier.ang@honestfox.com.au';
    // elementDebugEmail.setAttribute('type', 'hidden');
    // form.appendChild(elementDebugEmail);

    // First name
    const elementFirstName = document.createElement('input');
    elementFirstName.name = 'first_name';
    elementFirstName.value = data.first_name;
    elementFirstName.setAttribute('type', 'hidden');
    form.appendChild(elementFirstName);

    // Last name
    const elementLastName = document.createElement('input');
    elementLastName.name = 'last_name';
    elementLastName.value = data.last_name;
    elementLastName.setAttribute('type', 'hidden');
    form.appendChild(elementLastName);

    // combined name
    // const elementLastName = document.createElement('input');
    // elementLastName.name = 'full_name';
    // elementLastName.value = data.full_name;
    // elementLastName.setAttribute('type', 'hidden');
    // form.appendChild(elementLastName);

    // Email address
    const elementEmailAddress = document.createElement('input');
    elementEmailAddress.name = 'email';
    elementEmailAddress.value = data.emailAddress;
    elementEmailAddress.setAttribute('type', 'hidden');
    form.appendChild(elementEmailAddress);

    // Phone
    const elementPhone = document.createElement('input');
    elementPhone.name = 'phone';
    elementPhone.value = data.phone;
    elementPhone.setAttribute('type', 'hidden');
    form.appendChild(elementPhone);

    // // Company
    // const elementCompany = document.createElement('input');
    // elementCompany.name = 'company';
    // elementCompany.value = data.company;
    // elementCompany.setAttribute('type', 'hidden');
    // form.appendChild(elementCompany);

    // Dealership Type
    const elementDealerType = document.createElement('input');
    elementDealerType.name = '00ND00000064sXT';
    elementDealerType.value = data.dealershipType;
    elementDealerType.setAttribute('type', 'hidden');
    form.appendChild(elementDealerType);

    // Dealership Sizes employees
    // const elementDealerSize = document.createElement('input');
    // elementDealerSize.name = 'employees';
    // elementDealerSize.value = data.dealershipSize;
    // elementDealerSize.setAttribute('type', 'hidden');
    // form.appendChild(elementDealerSize);

    // Preferrer Contact Method
    // const elementContactMethod = document.createElement('input');
    // elementContactMethod.name = '00N4G000006r1sM';
    // elementContactMethod.value = data.preferredContactMethod;
    // elementContactMethod.setAttribute('type', 'hidden');
    // form.appendChild(elementContactMethod);

    // Message
    const elementMessage = document.createElement('input');
    elementMessage.name = '00N4G000006r1sH';
    elementMessage.value = data.message;
    elementMessage.setAttribute('type', 'hidden');
    form.appendChild(elementMessage);

    // Lead source
    const elementLeadSource = document.createElement('input');
    elementLeadSource.name = 'lead_source';
    elementLeadSource.value = 'Web form - Book a demo';
    elementLeadSource.setAttribute('type', 'hidden');
    form.appendChild(elementLeadSource);

    document.body.appendChild(form);

    form.submit();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className="u-body-md mb-4 lg:mb-6">
        Let us know your queries and our team will be in touch with you as soon
        as possible.
      </p>
      <div className="mb-5">
        <Controller
          name="first_name"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              value={value}
              error={!!errors?.first_name}
              helperText={errors?.first_name?.message}
              label="First Name*"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </div>
      <div className="mb-5">
        <Controller
          name="last_name"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              value={value}
              error={!!errors?.last_name}
              helperText={errors?.last_name?.message}
              label="Last Name*"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </div>
      {/* <div className="mb-5">
        <Controller
          name="full_name"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              value={value}
              error={!!errors?.full_name}
              helperText={errors?.full_name?.message}
              label="Name*"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </div> */}
      <div className="mb-5">
        <Controller
          name="emailAddress"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              value={value}
              error={!!errors?.emailAddress}
              helperText={errors?.emailAddress?.message}
              label="Email Address*"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </div>
      <div className="mb-5">
        <Controller
          name="phone"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              value={value}
              error={!!errors?.phone}
              helperText={errors?.phone?.message}
              label="Phone"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </div>
      {/* <div className="mb-5">
        <Controller
          name="company"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              onChange={onChange}
              value={value}
              error={!!errors?.company}
              helperText={errors?.company?.message}
              label="Company"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </div> */}
      {/* <div className="grid grid-cols-2 gap-x-3 mb-5">
        <div className="col-span-1">
          <Controller
            name="dealershipType"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField
                select
                SelectProps={{
                  MenuProps: {
                    MenuListProps: {
                      className: 'list-none',
                    },
                  },
                }}
                onChange={onChange}
                value={value}
                error={!!errors?.dealershipType}
                helperText={errors?.dealershipType?.message}
                label="Dealership Type"
                variant="outlined"
                fullWidth
              >
                <MenuItem value="Motor Vehicle">Motor Vehicle</MenuItem>
                <MenuItem value="Motor Cycle">Motor Cycle</MenuItem>
                <MenuItem value="Marine">Marine</MenuItem>
                <MenuItem value="Agriculture">Agriculture</MenuItem>
                <MenuItem value="Heavy Machinery">Heavy Machinery</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </TextField>
            )}
          />
        </div>
        <div className="col-span-1">
          <Controller
            name="dealershipSize"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <TextField
                select
                SelectProps={{
                  MenuProps: {
                    MenuListProps: {
                      className: 'list-none',
                    },
                  },
                }}
                onChange={onChange}
                value={value}
                error={!!errors?.dealershipSize}
                helperText={errors?.dealershipSize?.message}
                label="Dealership Size"
                variant="outlined"
                fullWidth
              >
                <MenuItem value="1-19">1-19</MenuItem>
                <MenuItem value="20-99">20-99</MenuItem>
                <MenuItem value="100-199">100-199</MenuItem>
                <MenuItem value="200+">200+</MenuItem>
              </TextField>
            )}
          />
        </div>
      </div> */}

      <div className="mb-5">
        <Controller
          name="dealershipType"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              select
              SelectProps={{
                MenuProps: {
                  MenuListProps: {
                    className: 'list-none',
                  },
                },
              }}
              onChange={onChange}
              value={value}
              error={!!errors?.dealershipType}
              helperText={errors?.dealershipType?.message}
              label="Dealership Type"
              variant="outlined"
              fullWidth
            >
              <MenuItem value="Motor Vehicle">Motor Vehicle</MenuItem>
              <MenuItem value="Motor Cycle">Motor Cycle</MenuItem>
              <MenuItem value="Marine">Marine</MenuItem>
              <MenuItem value="Agriculture">Agriculture</MenuItem>
              <MenuItem value="Heavy Machinery">Heavy Machinery</MenuItem>
              <MenuItem value="Other">Other</MenuItem>
            </TextField>
          )}
        />
      </div>

      {/* <div className="mb-5">
        <Controller
          name="preferredContactMethod"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              select
              SelectProps={{
                MenuProps: {
                  MenuListProps: {
                    className: 'list-none',
                  },
                },
              }}
              onChange={onChange}
              value={value}
              error={!!errors?.preferredContactMethod}
              helperText={errors?.preferredContactMethod?.message}
              label="Preferred contact method"
              variant="outlined"
              fullWidth
            >
              <MenuItem value="Phone">Phone</MenuItem>
              <MenuItem value="Email">Email</MenuItem>
            </TextField>
          )}
        />
      </div> */}
      <div className="mb-5">
        <Controller
          name="message"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => (
            <TextField
              multiline
              rows={4}
              onChange={onChange}
              value={value}
              error={!!errors?.message}
              helperText={errors?.message?.message}
              label="Additional comments/questions"
              variant="outlined"
              fullWidth
            />
          )}
        />
      </div>
      <Button
        as="button"
        type="submit"
        variant="primary"
        label="Submit"
        disabled={!isValid || state.submitting}
        aria-label="Submit the book a demo form"
      />
    </form>
  );
};

export default BookADemoForm;
