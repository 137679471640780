import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Atoms
import TestimonialCard from '../../atoms/card-testimonial';

// Components
import { shrinkArray } from '../helpers';

const ContainerWrapper = styled.div`
  margin: 4rem auto !important;

  @media (min-width: 1024px) {
    margin: 6rem auto !important;
  }
`;

const TestimonialBlock = ({ title, description, testimonials }) => {
  const [isAnyOpen, setIsAnyOpen] = useState(false);
  const [shrinkTestimonial, setShrinkTestimonial] = useState([]);

  useEffect(() => {
    setShrinkTestimonial(shrinkArray(testimonials, 2));
  }, []);

  return (
    <ContainerWrapper className="xl:container mobile:px-4 tablet:px-8 lg:px-16">
      {title && <h2 className="u-h4 lg:mb-4">{title}</h2>}
      {description && <p>{description}</p>}
      {testimonials && testimonials.length > 1 && (
        <div className="flex flex-col items-stretch lg:flex-row mt-8 lg:mt-14">
          {shrinkTestimonial.map((testimonial, key) => (
            <TestimonialCard
              key={`testimonial-card-${key}`}
              title={testimonial.title}
              description={testimonial.description.description}
              companyName={testimonial.companyName}
              authorImage={testimonial.authorImage}
              authorName={testimonial.authorName}
              authorJobTitle={testimonial.authorJobTitle}
              isAnyOpen={isAnyOpen}
              setIsAnyOpen={setIsAnyOpen}
            />
          ))}
        </div>
      )}
    </ContainerWrapper>
  );
};
TestimonialBlock.defaultProps = {
  title: '',
  description: '',
};

TestimonialBlock.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.shape({
        description: PropTypes.string,
      }),
      companyName: PropTypes.string,
      authorImage: PropTypes.object,
      authorName: PropTypes.string.isRequired,
      authorJobTitle: PropTypes.string,
    })
  ).isRequired,
};

export default TestimonialBlock;
