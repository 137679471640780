/*
========================================================================||
Builder Logic
- to render out specific components based on the __typename from contentful

TODO: Add more as we go
Supported modules:
- Media Block (ContentfulMediaBlock)
- Benefit Block (ContentfulBenefitBlock)
- DMS Block (ContentfulDmsBlock)
- Content Block (ContentfulContentBlock)
- Video Block (ContentfulVideoBlock)
- Partner Block (ContentfulPartnerBlock)
- CTA Block (ContentfulCtaBlock)
- Getting on board Block (ContentfulGettingOnBoardBlock)
- Testimonial Block (ContentfulTestimonialBlock)
- Team member Block (ContentfulTeamMemberBlock)
- Form Block (ContentfulFormBlock)

========================================================================||
*/

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

// Components
import MediaBlock from './blocks/media-block';
import BenefitBlock from './blocks/benefit-block';
import DmsBlock from './blocks/dms-block';
import ContentBlock from './blocks/content-block';
import VideoBlock from './blocks/video-block';
import PartnerBlock from './blocks/partner-block';
import CtaBlock from './blocks/cta-block';
import GettingOnBoardBlock from './blocks/getting-on-board-block';
import TestimonialBlock from './blocks/testimonial-block';
import TeamMemberBlock from './blocks/team-member-block';
import FormBlock from './blocks/form-block';
import { doesBlockHaveBG } from './helpers';

const Builder = React.forwardRef(({ type, params, index, ...props }, ref) => {
  const renderBlock = (param) => {
    switch (param) {
      case 'ContentfulBenefitBlock':
        return (
          <BenefitBlock
            className={props.className}
            title={params.title}
            description={params.description}
            benefits={params.benefits}
            ref={ref}
            name={param}
          />
        );
      case 'ContentfulMediaBlock':
        return (
          <MediaBlock
            className={props.className}
            title={params.title}
            body={params.body}
            buttons={params.buttons}
            image={params.image}
            isReversed={!(index % 2)}
            ref={ref}
            name={param}
          />
        );
      case 'ContentfulDmsBlock':
        return (
          <DmsBlock
            className={props.className}
            title={params.title}
            description={params.description}
            firstCard={{
              title: params.firstCardTitle,
              description: params.firstCardDescription,
              image: params.firstCardImage,
              reference: params.firstCardReference,
            }}
            secondCard={{
              title: params.secondCardTitle,
              description: params.secondCardDescription,
              image: params.secondCardImage,
              reference: params.secondCardReference,
            }}
            thirdCard={{
              title: params.thirdCardTitle,
              description: params.thirdCardDescription,
              image: params.thirdCardImage,
              reference: params.thirdCardReference,
            }}
            ref={ref}
            name={param}
          />
        );
      case 'ContentfulContentBlock':
        return (
          <ContentBlock
            className={props.className}
            title={params.title}
            body={params.body}
            buttons={params.buttons}
            ref={ref}
            name={param}
          />
        );
      case 'ContentfulVideoBlock':
        return (
          <VideoBlock
            className={props.className}
            title={params.title}
            description={params.description}
            video={{
              platform: params.videoPlatform,
              id: params.videoId,
              thumbnail: params.videoThumbnail,
            }}
            ctas={params.videoCtas}
            ref={ref}
            name={param}
          />
        );
      case 'ContentfulPartnerBlock':
        return (
          <PartnerBlock
            className={props.className}
            title={params.heading}
            partners={params.partners}
            ref={ref}
            name={param}
          />
        );
      case 'ContentfulCtaBlock':
        return (
          <CtaBlock
            className={props.className}
            title={params.title}
            description={params.description}
            buttons={params.buttons}
            ref={ref}
            name={param}
          />
        );
      case 'ContentfulGettingOnBoardBlock':
        return (
          <GettingOnBoardBlock
            className={props.className}
            title={params.title}
            description={params.description}
            buttons={params.buttons}
            benefits={params.benefits}
            ref={ref}
            name={param}
          />
        );
      case 'ContentfulTestimonialBlock':
        return (
          <TestimonialBlock
            className={props.className}
            title={params.title}
            description={params.description}
            buttons={params.buttons}
            testimonials={params.testimonials}
            ref={ref}
            name={param}
          />
        );
      case 'ContentfulTeamMemberBlock':
        return (
          <TeamMemberBlock
            className={props.className}
            title={params.title}
            description={params.description}
            buttons={params.buttons}
            members={params.teamMembers}
            ref={ref}
            name={param}
          />
        );
      case 'ContentfulFormBlock':
        return (
          <FormBlock
            title={params.title}
            description={params.description}
            benefits={params.benefits}
            ref={ref}
          />
        );
      default:
        return null;
    }
  };

  return <>{renderBlock(type)}</>;
});

Builder.defaultProps = {
  type: '',
  params: {},
  index: 0,
};

Builder.propTypes = {
  type: PropTypes.oneOf([
    '',
    'ContentfulBenefitBlock',
    'ContentfulMediaBlock',
    'ContentfulDmsBlock',
    'ContentfulContentBlock',
    'ContentfulVideoBlock',
    'ContentfulPartnerBlock',
    'ContentfulCtaBlock',
    'ContentfulGettingOnBoardBlock',
    'ContentfulTestimonialBlock',
    'ContentfulTeamMemberBlock',
    'ContentfulFormBlock',
  ]),
  params: PropTypes.object,
  index: PropTypes.number,
};

export default Builder;

export const BuilderWrapper = styled(Builder)`
  ${(props) => {
    if (props.isFirst && doesBlockHaveBG(props.type)) {
      return 'margin-top: -64px !important;';
    }
    if (
      (props.previousType &&
        doesBlockHaveBG(props.previousType) &&
        doesBlockHaveBG(props.type)) ||
      (props.previousType &&
        props.previousType === 'ContentfulPartnerBlock' &&
        doesBlockHaveBG(props.type))
    ) {
      return 'margin-top: 0px !important;';
    }
    if (
      props.type &&
      props.type === 'ContentfulPartnerBlock' &&
      props.previousType &&
      doesBlockHaveBG(props.previousType) &&
      props.nextType &&
      doesBlockHaveBG(props.nextType)
    ) {
      return 'margin-top: 0px !important; height: 0; > * { transform: translateY(-50%); }';
    }
  }}

  ${(props) => {
    if (
      props.previousType &&
      (props.previousType === 'ContentfulTeamMemberBlock' ||
        props.previousType === 'ContentfulTestimonialBlock') &&
      props.type &&
      (props.type === 'ContentfulTeamMemberBlock' ||
        props.type === 'ContentfulTestimonialBlock')
    ) {
      return 'padding-top: 0px !important;';
    }
  }}

  ${(props) => {
    if (props.isLast && doesBlockHaveBG(props.type)) {
      return 'margin-bottom: -64px !important;';
    }
  }}

  ${(props) => {
    if (
      props.type &&
      props.type === 'ContentfulPartnerBlock' &&
      props.nextType === 'ContentfulTestimonialBlock'
    ) {
      return 'background: linear-gradient(180deg, #FFFFFF 50%, #F9FAFC 50%);';
    }
  }}

  @media (min-width: 1024px) {
    ${(props) => {
      if (props.isFirst && doesBlockHaveBG(props.type)) {
        return 'margin-top: -128px !important;';
      }
      if (
        (props.previousType &&
          doesBlockHaveBG(props.previousType) &&
          doesBlockHaveBG(props.type)) ||
        (props.previousType &&
          props.previousType === 'ContentfulPartnerBlock' &&
          doesBlockHaveBG(props.type))
      ) {
        return 'margin-top: 0px !important;';
      }
    }}

    ${(props) => {
      if (props.isLast && doesBlockHaveBG(props.type)) {
        return 'margin-bottom: -128px !important;';
      }
    }}
  }
`;
