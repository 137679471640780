import React from 'react';
import PropTypes from 'prop-types';

// Atoms
import IconCard from '../../atoms/card-icon';

// Components
import Carousel from '../carousel';

const GettingOnBoardBlock = ({
  title,
  description,
  buttons,
  benefits,
  ...props
}) => {
  return (
    <Carousel
      className={props.className}
      title={title}
      description={description}
      buttons={buttons}
      slides={benefits.map((benefit, key) => (
        <IconCard
          key={`getting-on-board-icon-card-${key}`}
          title={benefit.title}
          description={benefit.description}
          icon={benefit.icon}
          size="medium"
        />
      ))}
      numberOfSlides={{
        mobile: 1.2,
        tablet: 2.2,
        desktop: 4,
      }}
      isCenter
    />
  );
};

GettingOnBoardBlock.defaultProps = {
  title: '',
  description: '',
  buttons: [],
};

GettingOnBoardBlock.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      label: PropTypes.string,
      ariaLabel: PropTypes.string,
      variant: PropTypes.oneOf(['Primary', 'Secondary', 'Tertiary']),
      opensInNewTab: PropTypes.bool,
      externalUrl: PropTypes.string,
      internalUrl: PropTypes.arrayOf(PropTypes.object),
    })
  ),
  benefits: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      icon: PropTypes.string,
    })
  ).isRequired,
};

export default GettingOnBoardBlock;
