import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cn from 'classnames';

// Atoms
import CardPartner from '../../atoms/card-partner';

const propTypes = {
  title: PropTypes.string,
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      logo: PropTypes.object, // GatsbyImage
    })
  ).isRequired,
};

const defaultProps = {
  title: '',
};

const PartnerBlock = React.forwardRef(
  ({ title, partners, className, ...props }, ref) => {
    return (
      <div className={cn('relative z-10', className)}>
        <div
          className="xl:container mobile:px-4 tablet:px-8 lg:px-16 "
          ref={ref}
          data-name={props.name}
        >
          <div className="bg-white rounded pt-7 pb-7 lg:pb-14 shadow-default">
            {title && (
              <div className="grid grid-cols-12 gap-4 lg:gap-8 mb-6 lg:mb-8">
                <div className="col-span-10 col-start-2 lg:col-span-6 lg:col-start-4">
                  <h2 className="u-h5 text-grey-font-1 text-center">{title}</h2>
                </div>
              </div>
            )}
            <div className="flex items-center justify-evenly gap-y-8 gap-x-8 md:gap-x-12 lg2:gap-x-24 lg:justify-between flex-wrap md:flex-nowrap px-6 lg:px-16">
              {partners.map((partner, key) => (
                <CardPartner
                  key={`partner-${key}`}
                  logo={partner.logo}
                  className="flex-[0_0_calc((100%-64px)/3)] max-w-[100px] md:flex-auto"
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

// height: ${(props) => `${props.innerRef.current.clientHeight / 2}px`};
export const PartnerNegative = styled(PartnerBlock)`
  height: ${(props) =>
    props.offsetHeight ? `${props.offsetHeight}px` : 'auto'};
  > * {
    position: relative;
    z-index: 1;
    transform: translateY(-50%);
  }
`;

// export const PartnerNegative = styled.div.attrs({
//   className: 'relative',
// })`
//   height: 0px;
//   > * {
//     position: relative;
//     z-index: 1;
//     transform: translateY(-50%);
//   }
// `;

PartnerBlock.propTypes = propTypes;
PartnerBlock.defaultProps = defaultProps;

export default PartnerBlock;
