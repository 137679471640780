import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

// Atoms
import DmsCard from '../../atoms/card-dms';
import ImageWrapper from '../../atoms/image-wrapper';

const DmsBlock = React.forwardRef(
  ({ title, description, firstCard, secondCard, thirdCard, ...props }, ref) => {
    return (
      <div
        className={cn(
          'bg-gradient-180 from-blue-dark to-blue-bold text-white py-6 lg:py-32',
          props.className
        )}
        ref={ref}
      >
        <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16">
          {(title || description) && (
            <div className="grid grid-cols-12 gap-x-4 lg:gap-x-8 mb-6 lg:mb-16">
              <div className="col-span-12 lg:col-span-7">
                <div className="space-y-3">
                  {title && <h2 className="u-h3">{title}</h2>}
                  {description && <p>{description}</p>}
                </div>
              </div>
            </div>
          )}
          <div className="grid grid-cols-12 gap-4 lg:gap-8">
            <div className="col-span-12 lg:col-span-6">
              <ImageWrapper variant="secondary">
                <DmsCard
                  title={firstCard?.title}
                  description={firstCard?.description}
                  image={firstCard?.image}
                  reference={firstCard?.reference}
                  isFirst
                  hasThird={!!(thirdCard?.title && thirdCard.image)}
                />
              </ImageWrapper>
            </div>
            <div className="col-span-12 lg:col-span-6 flex flex-col justify-between space-y-4 lg:space-y-8">
              <DmsCard
                title={secondCard?.title}
                description={secondCard?.description}
                image={secondCard?.image}
                reference={secondCard?.reference}
                isFirst={false}
                hasThird={!!(thirdCard?.title && thirdCard.image)}
              />
              {thirdCard?.title && thirdCard?.image && (
                <DmsCard
                  title={thirdCard?.title}
                  description={thirdCard?.description}
                  image={thirdCard?.image}
                  reference={thirdCard?.reference}
                  isFirst={false}
                  hasThird={!!(thirdCard?.title && thirdCard.image)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

DmsBlock.defaultProps = {
  title: '',
  description: '',
  thirdCard: {},
};

DmsBlock.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  firstCard: PropTypes.object.isRequired,
  secondCard: PropTypes.object.isRequired,
  thirdCard: PropTypes.object,
};

export default DmsBlock;
