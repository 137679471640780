import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// Gradients
import GradientOverlay from '../gradients/image-shadow.svg';

// Components
import { generateUrlFromContentModel } from '../components/helpers';

// shadow overlay
const ImageWrapper = styled.a`
  ::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 4px;
    left: 0;
    opacity: 0.7;
    background: url('${GradientOverlay}');
    background-size: cover;
  }
`;

const DmsCard = ({
  title,
  description,
  image,
  reference,
  isFirst,
  hasThird,
}) => {
  const img = getImage(image);

  if (reference) {
    return (
      <ImageWrapper
        href={generateUrlFromContentModel(reference)}
        className="block relative group overflow-hidden"
        aria-label={`Navigating to ${reference.title} page`}
      >
        <GatsbyImage
          image={img}
          // title={image.title}
          alt={image.description}
          className={cn(
            'h-full',
            !isFirst && hasThird
              ? 'aspect-[343/190] lg:aspect-[590/204]'
              : 'aspect-[343/190] lg:aspect-[590/440]'
          )}
        />
        <div className="absolute bottom-6 left-6 max-w-[70%] z-10">
          {title && (
            <h3 className="text-h4-m lg:text-h3-m mobile+tablet:underline group-hover:underline">
              {title}
            </h3>
          )}
          {description && (
            <p className="text-body-sm mt-1 lg:mt-1.5">{description}</p>
          )}
        </div>
      </ImageWrapper>
    );
  }
  return (
    <article className="relative overflow-hidden h-full">
      <GatsbyImage
        image={img}
        // title={image.title}
        alt={image.description}
        className={
          !isFirst && hasThird
            ? 'aspect-[343/190] lg:aspect-[590/204]'
            : 'aspect-[343/190] lg:aspect-[590/440]'
        }
      />
      <div className="absolute bottom-6 left-6 max-w-[70%] z-10">
        {title && <h3 className="text-h4-m lg:text-h3-m">{title}</h3>}
        {description && (
          <p className="text-body-sm mt-1 lg:mt-1.5">{description}</p>
        )}
      </div>
      <div className="absolute opacity-70 -bottom-1 left-0 w-full h-full touch-none pointer-events-none">
        <img
          aria-hidden
          alt=""
          src={GradientOverlay}
          className="w-full h-full"
        />
      </div>
    </article>
  );
};

DmsCard.defaultProps = {
  description: '',
  reference: {},
  isFirst: true,
  hasThird: false,
};

DmsCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.object.isRequired,
  reference: PropTypes.object,
  isFirst: PropTypes.bool,
  hasThird: PropTypes.bool,
};

export default DmsCard;
